<template>
    <div class="wrap">
      <div class="top">
        <img :src="img" alt="">
        <div class="top-t">
          <h1>{{ name }}</h1>
          <p>职位</p>
        </div>
      </div>
      <div class="item">
        <div class="index-title">
          <div class="t">业务触发</div>
        </div>
        <div class="block">
          <van-grid :column-num="3">
            <van-grid-item to="/business">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-lishiqingjia"></use>
              </svg>
              <p>业务入口</p>
            </van-grid-item>
            <van-grid-item to="/inventory">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-jiaban"></use>
              </svg>
              <p>库存查询</p>
            </van-grid-item>
            <van-grid-item to="/task">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-chucha"></use>
              </svg>
              <p>任务查询</p>
            </van-grid-item>
            <van-grid-item to="/other">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-huiyi-"></use>
              </svg>
              <p>辅助作业</p>
            </van-grid-item>
            <van-grid-item to="/recent">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-zhantingzhanlan"></use>
              </svg>
              <p>最近任务入口</p>
<!--              可以扫地码 可以扫工装-->
<!--              类似快捷入口 留地码和工装号-->
            </van-grid-item>
            <van-grid-item to="/oneKey">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-peixun"></use>
              </svg>
              <p>快捷入口</p>
            </van-grid-item>
            <van-grid-item to="/take">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#icon-peixun"></use>
              </svg>
              <p>盘点</p>
            </van-grid-item>
          </van-grid>
        </div>
      </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    name: "indexPage",
    inject: ['goUrl'],
    computed: {
        ...mapGetters(['name', 'avatar', 'userId']),
    },
    data() {
        return{
          img: 'https://router.ubeiyun.com/avatar/time.jpg'
        }
    },
    created() {
    },
    methods: {
    }
}
</script>

<style scoped>
.top{
  width: 100%;
  height: 105px;
  background: url("../../assets/index-top-bgc.png") no-repeat;
  background-size: cover;
  color: #fff;
  display: flex;
  padding: 20px;
}
.top img{
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.top-t{
  margin-left: 12px;
}
.top-t h1{
  font-size: 16px;
  font-weight: normal;
  margin-top: 10px;
}
.t{
  font-size: 14px;
  margin-top: 4px;
  padding: 8px;
}
.index-title{
  width: 97%;
  margin: 10px auto 0;
  background-color: #fff;
}
.item{
  background-color: #fff;
  width: 97%;
  margin: 0 auto;
}
.wrap{
  margin-bottom: 10vh;
}
.block p{
  margin-top: 8px;
}
</style>

